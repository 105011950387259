import { useContext, useMemo } from "react";
import PropTypes from "prop-types";

import { insertBoldText } from "../../helpers/insertBoldText";
import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseBenefitsList = (props) => {
	const { company } = useContext(CompanyContext);
	const benefitsArray = company.fields.companyMainBenefits;

	const formattedBenefitsList = useMemo(
		() => insertBoldText(benefitsArray),
		[benefitsArray],
	);

	const companyBenefits = formattedBenefitsList.map((benefit, idx) => (
		<li key={idx} className={props?.listItem}>
			{props?.icon}
			{benefit}
		</li>
	));

	return (
		<div className={props?.wrapper}>
			<ul className={props?.list}>{companyBenefits}</ul>
		</div>
	);
};

BaseBenefitsList.propTypes = {
	wrapper: PropTypes.string,
	list: PropTypes.string,
	listItem: PropTypes.string,
	icon: PropTypes.element,
};
