import { logError } from "./logError";

export const insertBoldText = (benefitsList) => {
	if (!Array.isArray(benefitsList)) return [];

	return benefitsList.map((benefit, benefitIndex) => {
		try {
			if (benefit.includes("<b>") && benefit.includes("</b>")) {
				// split the string on <b> and </b> markers
				const stringPieces = benefit.split(/(<b>.*?<\/b>)/);
				const formattedStringPieces = stringPieces
					.map((piece, index) => {
						if (piece.startsWith("<b>") && piece.endsWith("</b>")) {
							// return span with bold styling
							const boldText = piece.replace(/<\/?b>/g, "");
							return (
								<span key={`${benefitIndex}-${index}`} className="tw-font-bold">
									{boldText}
								</span>
							);
						}
						// return plain text for non-bold pieces
						return piece;
					})
					.filter(Boolean);

				return formattedStringPieces;
			}

			// if no tags, return unprocessed
			return benefit;
		} catch (error) {
			logError(
				`Error applying bold formatting to company benefit at index ${benefitIndex}: ${benefit}`,
			);
			return benefit;
		}
	});
};
