import PropTypes from "prop-types";

import { SocialProofIcon } from "../../client/assets/icons/common/SocialProofIcon";

export const BaseSocialProof = (props) => {
	const { contentText = "85% of our visitors chose this!" } = props;

	return (
		<div className={props?.wrapper}>
			<div className={props?.contentWrapper}>
				<SocialProofIcon
					className={props?.contentIcon}
					fill={props?.contentIconFill}
				/>
				<p className={props?.content}>{contentText}</p>
			</div>
		</div>
	);
};

BaseSocialProof.propTypes = {
	wrapper: PropTypes.string,
	contentWrapper: PropTypes.string,
	contentIcon: PropTypes.string,
	contentIconFill: PropTypes.string,
	content: PropTypes.string,
	contentText: PropTypes.string,
};
